

.institute-container {
    padding-top: 100px;
}

.institute-name {
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    border-bottom: 1px solid #D3D3D3;
    margin: 20px 30px;
}

.instituteName > h2 {
    font-weight: bold;
}

.instituteLogo-img {
    height: 100px;
}

.login-container {
    margin-top: 30px;
    padding: 40px 30px;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
}

.login-form {
    padding: 120px 60px;
    background: rgba(255, 255, 255, 0.05);
    border: 2px solid #FFFFFF;
    backdrop-filter: blur(4px);
    border-radius: 20px;
}

.login-form input {
    background-color: transparent;
    color: #000000;
    font-size: 16px !important;
    border-right: none;
    border-left: none;
    border-top: none;
    border-bottom: 1px solid #000000;
    border-radius: 0;
}

.login-form input:focus {
    background-color: transparent;
    color: #000000;
    font-size: 16px !important;
    border-right: none;
    border-left: none;
    border-top: none;
    border-radius: 0;
    box-shadow: none;
    border-bottom: 1px solid #000000;
}

.login-form input::placeholder {
    color: #000000;
    font-size: 14px;
}

.submit-login {
    background-color: navy;
    border-radius: 10px;
    color: #FFFFFF;
}

.submit-login:hover {
    color: #FFFFFF;
}

.forgot-password {
    text-align: right;
    color: #000000;
}

.forgot-password-text {
    cursor: pointer;
}

.responseMessage {
    display: flex;
    justify-content: center;
}

.responseText {
    width: fit-content;
}

.access-account-button {
    text-align: center;
}

.access-account {
    background-color: navy;
    border-radius: 10px;
    color: #FFFFFF;
}

.access-account:hover {
    color: #FFFFFF;
}

@media only screen and (max-width: 540px) {
    .instituteLogo-img {
        height: 60px;
    }
    
    .instituteName > h2 {
        font-size: 22px;
    }

    .login-form {
        padding: 40px 20px;
        backdrop-filter: blur(2px);
    }
}