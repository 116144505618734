

.passwordReset-container {
    margin-top: 30px;
    padding: 40px 30px;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
}

.passwordReset-form {
    padding: 40px 60px;
    background: rgba(255, 255, 255, 0.05);
    border: 2px solid #FFFFFF;
    backdrop-filter: blur(4px);
    border-radius: 20px;
}

.passwordResetHeader {
    margin-bottom: 50px;
}

.passwordResetHeader > h4{
    font-weight: bold;
}

.passwordReset-form input {
    background-color: transparent;
    color: #000000;
    font-size: 16px !important;
    border-right: none;
    border-left: none;
    border-top: none;
    border-bottom: 1px solid #000000;
    border-radius: 0;
}

.passwordReset-form input:focus {
    background-color: transparent;
    color: #000000;
    font-size: 16px !important;
    border-right: none;
    border-left: none;
    border-top: none;
    border-radius: 0;
    box-shadow: none;
    border-bottom: 1px solid #000000;
}

.passwordReset-form input::placeholder {
    color: #000000;
    font-size: 14px;
}

.submit-passwordReset {
    background-color: navy;
    border-radius: 10px;
    color: #FFFFFF;
}

.submit-passwordReset:hover {
    color: #FFFFFF;
}

.return-login {
    text-align: center;
    color: #000000;
    margin-top: 50px;
}

.return-login-text {
    cursor: pointer;
    font-weight: bold;
}

.query-details {
    text-align: center;
}

@media only screen and (max-width: 540px) {

    .passwordReset-form {
        padding: 40px 20px;
        backdrop-filter: blur(2px);
    }
}