
.webInfo-container {
    padding-top: 100px;
}

#scrollButton {
    display: none;
    position: fixed;
    bottom: 15%;
    right: 30px;
    z-index: 100;
    font-size: 18px;
    border: none;
    outline: none;
    color: #000000;
    cursor: pointer;
    background-color: #FFFFFF;
    padding: 15px;
    border-radius: 50%;
    padding: 20px;
}

.pageHeader {
    text-align: center;
}

.pageHeader > h2 {
    font-weight: bold;
}

.pageInfo {
    margin-top: 10px;
    font-family: 'Montserrat';
    text-align: center;
    font-size: 18px;
}

.key-features {
    display: flex;
}

.featuresData {
    padding-left: 50px;
    padding-right: 150px;
}

.outcome-education > h5 {
    text-align: center;
}

.academic-software > h5 {
    text-align: center;
}

.office-automation > h5 {
    text-align: center;
}

.outcome-header > h3 {
    font-weight: bold;
}

.academic-header > h3 {
    font-weight: bold;
}

.automation-header > h3 {
    font-weight: bold;
}

.our-presence > h2 {
    font-weight: bold;
}

.institute-img {
    width: 100%;
}

.outcome-education {
    display: flex;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 40px;
}

.academic-software {
    display: flex;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
}

.office-automation {
    display: flex;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 20px;
}

.client-count {
    display: flex;
    align-items: center;
    padding: 0;
    margin-top: 20px;
}

.active-users {
    display: flex;
    justify-content: center;
    border-right: 1px solid #C4C4C4;
}

.state-presence {
    display: flex;
    justify-content: center;
    border-right: 1px solid #C4C4C4;
    
}

.active-clients {
    display: flex;
    justify-content: center;
    
}

.client-img {
    width: 50px;
    height: 50px;
    margin-right: 30px;
}

.featured-title {
    text-align: center;
}

.featured-header {
    font-size: 18px;
    font-weight: bold;
    margin-top: 60px;
}

.outcomeEducation {
    padding: 20px;
    background-image: linear-gradient(107.11deg, #D9EFFF 0%, rgba(204, 255, 215, 0.896818) 0%, rgba(255, 255, 255, 0) 100%);
}

.outcome-header {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #C4C4C4;
}

.outcome-header-image {
    width: 80px;
    height: 80px;
}

.options-container {
    display: flex;
    justify-content: center;
}

.outcome-options {
    display: flex;
    text-align: center;
    margin-top: 50px;
    margin-left: 50px;
    margin-right: 50px;
}

.option-item {
    padding-right: 70px;
    padding-left: 70px;
}

.academicManagement {
    padding: 20px;
    background-image: linear-gradient(107.11deg, #D9EFFF 0%, rgba(255, 241, 204, 0.896818) 0%, rgba(255, 255, 255, 0) 100%);
}

.academic-header {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #C4C4C4;
}

.officeAutomation {
    padding: 20px;
    background-image: linear-gradient(107.11deg, #DB0404 0%, rgba(204, 233, 255, 0.896818) 0%, rgba(255, 255, 255, 0) 100%);
}

.automation-header {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #C4C4C4;
}

.our-presence {
    margin-top: 40px;
}

.presence-container {
    display: flex;
    flex-wrap: wrap;
    padding-left: 100px;
    padding-right: 100px;
}

.presence-card {
    padding: 20px;
    border: 1px solid #C4C4C4;
    border-radius: 20px;
    text-align: center;
    width: 20%;
    margin: 20px;
}

.presence-img {
    width: 100px;
    height: 100px;
    margin-bottom: 30px;
}

.technology-partners {
    padding: 20px 30px;
    margin-top: 20px;
    /* background-color: #232F71; */
}

.technology-partners > h3 {
    font-weight: bold;
    color: black;
}

.technology-partners-list {
    /* display: flex;
    flex-wrap: wrap; */
}

.technology-partner {
    /* width: 22%; */
    /* margin: 20px; */
    /* padding: 20px; */
    height: 100%;
    background-color: #FFFFFF;
    /* border: 1px solid #D3D3D3; */
    border-radius: 20px;
    /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); */
    display: flex;
    align-items: center;
    justify-content: center;
}

.aws-img {
    width: 150px;
}

.googleCloud-img {
    width: 250px;
}

.sendinblue-img {
    width: 250px;
}

.sendgrid-img {
    width: 150px;
}

.textLocal-img {
    width: 150px;
}

.company-integrations {
    padding: 20px 30px;
    margin-top: 20px;
}

.company-integrations > h3 {
    font-weight: bold;
}

.company-integrations-list {
    display: flex;
    justify-content: center;
}

.company-integration {
    /* width: 22%; */
    margin: 20px;
    /* padding: 20px; */
    /* background-color: #FFFFFF; */
    /* border: 1px solid #D3D3D3; */
    /* border-radius: 20px; */
    /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); */
    /* display: flex; */
    align-items: center;
    /* justify-content: center; */
}

.googlemeet-img {
    width: 150px;
}

.zoom-img {
    width: 250px;
}

.razorpay-img {
    width: 250px;
}

.payU-img {
    width: 150px;
}

.automateio-img {
    width: 150px;
}

.company-certifications {
    padding: 20px 30px;
    /* background: radial-gradient(100% 100% at 50% 0%, #17BCD3 0%, rgba(49, 145, 186, 0.939236) 31.42%, rgba(95, 6, 241, 0.5) 100%); */
}

.company-certifications > h3 {
    font-weight: bold;
    /* color: #FFFFFF; */
}

.company-certifications-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.company-certification {
    width: 30%;
    margin: 20px;
    padding: 20px;
    border: 1px solid #D3D3D3;
    background-color: #FFFFFF;
    border-radius: 20px;
    /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); */
    text-align: center;
}

.ISO-header > h5 {
    font-weight: bold;
}

.SCGJ-header > h5 {
    font-weight: bold;
}


.NASSCOM-header > h5 {
    font-weight: bold;
}


.DIPP-header > h5 {
    font-weight: bold;
}


.RGAP-header > h5 {
    font-weight: bold;
}

.testimonials {
    margin-top: 40px;
}

.testimonials > h3 {
    font-weight: bold;
}

.empName {
    font-size: 20; 
    margin-bottom: 0;
}

.designation {
    font-size: 14px;
}

.testimonials-container {
    background-color: #F7F7F7;
    display: flex;
    justify-content: space-between;
    padding: 40px;
    margin-top: 30px;
}

.testimonial-1 {
    background-color: #FFFFFF;
    width: 48%;
    padding: 20px;
}

.testimonial-2 {
    background-color: #FFFFFF;
    width: 48%;
    padding: 20px;
}

.testimonial-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.testimonial-pic {
    height: 100px;
    margin-right: 30px;
}

.enquiry {
    padding-top: 100px;
}

.enquiry > h2 {
    font-weight: bold;
}

.enquiry-container {
    margin-top: 30px;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
}

.submit-enquiry {
    margin-top: 20px;
    text-align: center;
    border-bottom: 1px solid black;
}

.enquiry-button {
    background-color: #921E1E;
    color: #FFFFFF;
    margin-bottom: 40px;
    font-size: 16px !important;
    border-radius: 10px;
}

.enquiry-button:hover {
    background-color: #921E1E;
    color: #FFFFFF;
}

.team-numbers {
    margin-top: 30px;
    font-size: 20px;
    display: flex;
    justify-content: center;
}

.fullName input {
    font-size: 16px !important;
}

.emailId input {
    font-size: 16px !important;
}

.contact input {
    font-size: 16px !important;
}

.note-data textarea {
    font-size: 16px !important;
}

.teamTable td {
    font-size: 20px;
}

.teamTable tr {
    margin-top: 20px;
    margin-bottom: 20px;
}

.address {
    margin-bottom: 0 !important;
    line-height: 1.5 !important;
}

.location-icon {
    margin-right: 10px; 
    margin-top: 5px; 
}

@media only screen and (max-width: 540px) {
    
    .googleMeetImg{
        height: 80%;
        width: 80%;
        margin-left: 9%;
    }
    .zoomImg{
        height: 80%;
        width: 100%;
    }
    
    .pageInfo {
        font-size: 14px;
    }

    .outcome-education {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .outcome-education > h5 {
        font-size: 16px;
    }

    .academic-software {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .academic-software > h5 {
        font-size: 16px;
    }

    .office-automation {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .office-automation > h5 {
        font-size: 16px;
    }
    
    .key-features {
        flex-direction: column;
    }

    .featuresData {
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
    }

    .institute-img {
        margin-top: 20px;
        width: 100%;
        margin-bottom: 20px;
        display: none;
    }

    .client-img {
        margin-right: 0;
        margin-bottom: 10px;
    }
    
    .active-users {
        text-align: center;
        flex-direction: column;
        border-right: none;
        border-bottom: 1px solid #c4c4c4;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .state-presence {
        text-align: center;
        flex-direction: column;
        border-right: none;
        border-bottom: 1px solid #c4c4c4;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .active-clients {
        text-align: center;
        flex-direction: column;
        border-right: none;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .client-count {
        justify-content: flex-start;
        flex-direction: column;
        padding: 10px;
    }

    .featured-header {
        font-size: 14px;
        margin-top: 20px;
    }

    .outcome-header > h3 {
        font-size: 18px;
    }

    .outcome-options {
        flex-direction: column;
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
    }

    .option-item {
        padding: 10px;
    }

    .academic-header > h3 {
        font-size: 18px;
    }

    .automation-header > h3 {
        font-size: 18px;
    }

    .presence-container {
        padding-left: 0;
        padding-right: 0;
    }

    .our-presence > h2 {
        font-size: 20px;
    }

    .presence-card {
        width: 100%;
    }

    .presence-card > h6 {
        font-size: 14px;
    }

    .technology-partners {
        padding: 20px;
    }

    .technology-partner {
        width: 100%;
    }
    
    .aws-img {
        width: 150px;
    }
    
    .googleCloud-img {
        width: 170px;
    }
    
    .sendinblue-img {
        width: 170px;
    }
    
    .sendgrid-img {
        width: 150px;
    }
    
    .textLocal-img {
        width: 100px;
    }

    .company-integrations-list {
        flex-direction: column;
        margin-left:15%
    }

    .company-integrations {
        padding: 20px;
    }
    
    .company-integration {
        width: 100%;
        margin: 20px 0px;
    }

    .company-certifications {
        padding: 20px;
    }

    .company-certification {
        width: 100%;
    }

    .testimonials {
        margin-top: 20px;
    }

    .testimonials > h3 {
        font-size: 24px;
        padding: 20px !important;
    }

    .testimonials-container {
        flex-direction: column;
    }

    .testimonial-1 {
        width: 100%;
    }

    .empName {
        font-size: 16px;
    }

    .designation {
        font-size: 14px;
    }

    .testimonial-content > h5 {
        font-size: 14px;
    }

    .testimonial-2 {
        width: 100%;
        margin-top: 20px;
    }

    .office-locations {
        margin-top: 30px;
    }

    .enquiry > h2 {
        font-size: 20px;
    }

    .enquiry-container {
        flex-direction: column;
        margin-top: 10px;
        font-size: 14px;
    }

    .fullName input {
        font-size: 12px !important;
    }

    .emailId input {
        font-size: 12px !important;
    }

    .contact input {
        font-size: 12px !important;
    }

    .note-data textarea {
        font-size: 12px !important;
    }

    .enquiry-button {
        font-size: 14px !important;
        margin-bottom: 20px;
    }
    
    .teamTable td {
        font-size: 13px;
    }

    .office-header > h3 {
        font-size: 20px;
    }

    .location-icon {
        margin-top: 2px;
    }

    .institute-structure {
        padding-left: 15px;
        padding-right: 15px;
    }
}